import React from "react"
import styled from "styled-components"
import ArrowRight from "../../../images/right-arrow.svg"
import PhoneIcon from "../../../images/phone-icon.svg"
import MailIcon from "../../../images/mail-icon.svg"
import Img from "gatsby-image"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const TeamCard = ({ name, education, pic, phone, email, practiceType }) => {
  return (
    <CardContainer>
      <CardInner>
        <Img fluid={pic} alt={name} />
        {email && phone && (
          <ContactBox>
            <p>
              <img src={PhoneIcon} alt="Phone" />{" "}
              <a href={"tel:" + phone}>{phone}</a>
            </p>
            <p>
              <img src={MailIcon} alt="Mail" />
              <a href={"mailto:" + email}>{email}</a>
            </p>
          </ContactBox>
        )}
        <div className="topBox">
          <h3>{name}</h3>
          <p>{education}</p>
        </div>
        <div className="practice">
          <div>
            <h4>Preferred Areas Of Practice:</h4>
          </div>
          <ul>
            <>
              {practiceType.isSolicitor &&
                practiceType.solicitorServices.map((service) => (
                  <li key={service}>{service}</li>
                ))}
              {practiceType.isLitigation &&
                practiceType.litigationServices.map((service) => (
                  <li key={service}>{service}</li>
                ))}
            </>
          </ul>
        </div>
      </CardInner>
    </CardContainer>
  )
}
export default TeamCard

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const CardContainer = styled.div`
  background-color: #d8d8d84d;
  border-radius: 5px;
  position: sticky;
  top: 100px;
  margin-bottom: 30px;
`
const CardInner = styled.div`
  img {
    width: 100%;
    margin: 0px;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .topBox {
    padding: 20px;
    h3 {
      margin-bottom: 10px;
      color: ${(props) => props.theme.green};
    }
    p {
      margin-bottom: 0px;
      color: #5c5d5dd9;
    }
  }

  .practice {
    div {
      display: block;
      background-color: #d0d2d3;
      padding: 15px 20px;
    }

    h4 {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0;
    }
    ul {
      margin-top: 10px;
      list-style: none;
      padding-bottom: 10px;
      li {
        margin-bottom: 2px;
        margin-left: 15px;
      }
      li::before {
        content: url(${ArrowRight});
        display: inline-block;
        width: 0px;
        height: 6px;
        margin-left: -25px;
        float: left;
        transform: scale(0.03);
      }
    }
    li a {
      color: #787979;
      font-weight: 500;
      font-size: 15px;
      font-family: "Poppins", Arial, Helvetica, sans-serif;
    }
    li a:hover {
      color: ${(props) => props.theme.green};
    }
  }
`
const ContactBox = styled.div`
  background-color: ${(props) => props.theme.green};
  display: flex;
  flex-direction: row;
  p {
    text-align: left;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
  }
  img {
    margin-bottom: 0px;
    width: 25px;
    position: absolute;
    left: 4px;
    top: 8px;
  }
  a {
    background: inherit;
    display: inline-block;
    color: #fff;
    padding: 10px;
    font-size: 14px;
    :hover {
      color: ${(props) => props.theme.gold};
    }
  }
  p:nth-child(1) {
    margin-left: 20px;
    float: left;
    width: 44%;
  }
  p:nth-child(2) {
    width: 63%;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    p {
      width: 100% !important;
      margin: 10px !important;
    }
  }
`
