import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Inner = ({ children }) => <InnerContainer>{children}</InnerContainer>
export default Inner

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const InnerContainer = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  padding: 60px 20px;
`
